.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

#validation-control {
  z-index: 9999 !important;
}
