.modal-footer {
  display: inline-flex;
  max-height: 48px;
  width: 100%;
  height: 40px;
  flex-wrap: nowrap;
  z-index: 500;
}

.dx-popup-content .modal-footer {
  align-items: flex-end;
  padding: 5px;
  position: absolute;
  left: 0;
  bottom: 0;
  justify-content: flex-end;
}

.dx-popup-content .modal-footer > label {
  align-self: center;
}