.dx-datagrid-content .dx-datagrid-table .dx-row>td,
.dx-datagrid-content .dx-datagrid-table .dx-row>tr>td {
    white-space: normal !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td {
    vertical-align: bottom !important;
}

.dx-datagrid-headers {
    color: #484848;
    background-color: #f8f8f8;
    font-size: var(--main-font-size) !important;
}

.dark-theme .dx-datagrid-headers {
    color: hsla(0, 0%, 100%, 0.6);
    background-color: transparent;
}

.dx-datagrid-headers .dx-datagrid-table td {
    padding: 5px !important;
}

.dx-data-row.dx-state-hover>td {
    color: black !important;
}

.dark-theme .dx-data-row.dx-state-hover>td {
    color: white !important;
}

.dx-data-row.dx-row-focused>td {
    background-color: rgb(190, 226, 248) !important;
    color: black !important;
}

.dark-theme .dx-data-row.dx-row-focused>td {
    background-color: rgba(13, 131, 201, 0.4) !important;
    color: white !important;
}

.dx-data-row.dx-selection>td {
    background-color: rgba(190, 226, 248, 0.4) !important;
    color: black !important;
}

.dark-theme .dx-data-row.dx-selection>td {
    background-color: rgba(190, 226, 248, 0.9) !important;
    color: black !important;
}

.dark-theme .dx-data-row.dx-selection>td.dx-editor-cell {
    background-color: rgb(40, 51, 60) !important;
    color: white !important;
}

.dx-row.dx-column-lines>td {
    border-right-color: white !important;
}

.kAXZPY .dx-button-content {
    padding: 2px 4px 4px 4px !important;
}

.dx-button-text {
    font-size: 14px !important;
}

.dx-toolbar-items-container {
    height: 30px !important;
}

.dx-widget {
    font-size: var(--main-font-size) !important;
}

.dx-texteditor-input {
    min-height: 30px !important;
    height: 30px !important;
}

.dx-toolbar-items-container h6 {
    margin: 0px !important;
    font-size: 16px !important;
}

.dx-toolbar-item {
    padding: 0px 0px 0px 5px !important;
}

.dx-row.dx-header-row>td {
    border-right-color: var(--dataGrid-header) !important;
}

.dx-pager .dx-page-sizes .dx-page-size {
    padding: 4px 6px 4px 6px !important;
    /*font-size: 12px !important;*/
}

.dx-pager .dx-pages .dx-page {
    padding: 4px 6px 4px 6px !important;
    /*font-size: 12px !important;*/
}


/*
.dx-pager .dx-pages .dx-info {
  font-size: 12px !important;
}
*/

.dx-pager {
    height: 32px !important;
    padding-top: 6px !important;
    padding-bottom: 0px !important;
}

.dx-row.dx-datagrid-filter-row>td {
    border-right-color: var(--dataGrid-header) !important;
    padding: 0px 0px 0px 5px !important;
}

.dx-datagrid-filter-row .dx-menu {
    z-index: 1 !important;
}

.dx-row.dx-data-row.dx-row-lines.dx-column-lines>td {
    padding: 2px 5px 2px 5px !important;
    font-size: var(--main-font-size) !important;
}

.dx-row.dx-data-row.dx-row-lines.dx-column-lines>.dx-command-select.dx-editor-cell.dx-editor-inline-block {
    padding: 0px !important;
}

.dx-datagrid-rowsview {
    max-height: var(--gridContentHeight) !important;
}

.dx-searchbox .dx-texteditor-input,
.dx-searchbox .dx-placeholder:before {
    padding-left: 34px !important;
}

.dx-datagrid-filter-row .dx-texteditor-container .dx-texteditor-input-container .dx-texteditor-input {
    font-size: var(--main-font-size) !important;
}

.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
    font-size: var(--main-font-size) !important;
}

.dx-datagrid .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    font-size: var(--main-font-size) !important;
}

.dx-datagrid-header-panel .dx-toolbar {
    padding: 0px !important;
}

.dx-total-row {
    font-weight: bold;
    margin-right: -10px;
    margin-left: -10px;
    height: 20px;
    border-bottom: 1px solid rgb(235, 235, 235);
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
    margin-left: 0;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder:before,
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input {
    padding-left: 20px;
}

.dx-datagrid-filter-row .dx-menu {
    margin-left: 0;
}

.dx-editor-with-menu {
    padding-top: 4px;
}

.dark-theme .dx-row.dx-column-lines>td {
    border-right-color: var(--grey-separator) !important;
}

.dark-theme .dx-total-row {
    font-weight: normal;
    margin: 0px -10px;
    height: 20px;
    font-size: 13px;
    border-bottom: 1px solid var(--grey-separator) !important;
    padding: 0px 7px 5px 7px;
}

.dark-theme .dx-scrollable-container {
    max-height: unset !important;
}

.dx-scrollbar-horizontal {
    height: 8px !important;
}

.dx-row.dx-data-row.dx-row-lines.dx-column-lines>td>span {
    white-space: pre-line;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td:last-of-type>span {
    white-space: pre-line !important;
}

.dark-theme .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    margin-left: 0px !important;
}

.inline-textArea .dx-texteditor-input {
    height: unset !important;
    line-height: unset !important;
}

.dx-row.dx-data-row.dx-row-lines.dx-column-lines>td>div>span {
    white-space: pre-line;
}

.wrapped-column {
    white-space: normal;
    word-wrap: break-word;
}

.dx-datagrid-revert-tooltip {
    display: none;
}

.commitmentHistoryListWrap .dx-card.wide-card {
    max-width: 100% !important;
}