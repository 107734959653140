.button {
  margin-left: 10px;
  line-height: 14px;
  text-align: center;
  width: auto;
  border-radius: 2px;
  border: 0px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  height: 28px;
}

.page-button {
  background-color: rgba(255, 255, 255, 0.2);
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 0px;
  border: none;
}

.button-expression-builder {
  background-color: rgba(255, 255, 255, 0.06);
  border: none;
  height: 22px;
  width: 100%;
  margin-left: 2px;
  min-width: 200px;
  padding: 1px 1px;
}

.page-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.button-qcValidation {
  background-color: #3f4e5c;
  border: 'none';
}

.button-qcValidation:hover {
  background-color: #919191;
}

.button-back {
  background-color: #808080;
}

.button-back:hover {
  background-color: #919191;
}

.button-delete {
  background-color: red;
}

.button-delete:hover {
  background-color: #fc2e1e;
}

.button-default {
  background-color: #0d83c9;
}

.button-default:hover {
  background-color: #1eabfc;
}

.button-cancel {
  background-color: transparent;
}

.button-cancel:hover {
  background-color: #22a0f4;
}

.button-confirm {
  background-color: #0d83c9;
}

.button-confirm:hover {
  background-color: #1eabfc;
}

.button-green {
  background-color: #01b450;
}

.button-green:hover {
  background-color: #01b500;
}

.button-red {
  background-color: #f20000;
}

.button-red:hover {
  background-color: #f23c00;
}

.button:focus {
  outline: 0;
}

.button:disabled {
  opacity: 0.5;
}

.button-delete:disabled {
  opacity: 0.5;
}

.go-back-to-homepage {
  background-color: #0d83c9;
  border: 0px;
  width: 170px;
  height: 30px;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: -0.18px;
  color: #ffffff;
}

.go-back-to-homepage:hover {
  background-color: #1eabfc;
}

.button-file-type {
  height: 22px;
  background-color: #0d83c9;
}
