.DayPickerInput-OverlayWrapper {
  position: var(--position) !important;
  z-index: 30 !important;
}

.DayPicker {
  background-color: #FFFFFF;
  color: black;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background: #1c252e !important;
}

.DayPicker-Weekday {
  padding: 0.8em;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background: #1c252e;
}

:root {
  --position: fixed;
}

.DateBox .dx-dropdowneditor-icon {
  color: #8f8f8f !important;
  background-color: white !important;
  border: none !important;
  box-shadow: none;
}

.DateBox {
  z-index: 2500 !important;
}

.DateBox .dx-placeholder {
  text-align: center;
  font-size: 11px;
}

.DateBox .dx-placeholder:before {
  padding: 4px !important;
}

.DateBox .dx-overlay-content {
  background-color: #FFFFFF !important;
  border: transparent;
}

.DateBox .dx-show-clear-button .dx-icon-clear {
  border: none !important;
  content: url('../../images/clear_black.png') !important;
  background-color: transparent !important;
  width: 12px;
  height: 12px;
}

.DateBox .dx-button-normal.dx-button-mode-contained.dx-widget.dx-dropdowneditor-button {
  margin-right: 0px !important;
}

.DateBox .dx-widget {
  background-color: #FFFFFF !important;
  border: none;
}

.DateBox .dx-calendar-cell.dx-calendar-other-view,
.dx-calendar-cell.dx-calendar-empty-cell {
  color: #8f8f8f !important;
}

.DateBox .dx-calendar-cell {
  color: black !important;
}

.DateBox .dx-calendar-cell.dx-state-hover {
  color: black !important;
  background-color: #f5f5f5;
}

.DateBox .dx-calendar-body thead tr th {
  -webkit-box-shadow: inset 0 -1px 0 #1c252e;
  box-shadow: inset 0 -1px 0 #1c252e;
  color: #9f9f9f;
}

.DateBox .dx-texteditor-container {
  height: 22px;
}

.DateBox .dx-texteditor-input {
  color: black !important;
  background-color: white !important;
  padding: 5px;
  min-height: 22px !important;
  height: 22px !important;
  padding-right: 0px !important;
  font-size: var(--main-font-size) !important;
  /* border:none !important;
      font-size: 14 !important;
      
      min-width: '120px'!important;
      outline: 'none'!important;
      padding-left: 6!important;
      padding-right: 6!important;
      width: 10vw!important; */
}

.DateBox .dx-calendar {
  margin: 10px;
}

.DateBox .dx-clear-button-area {
  width: 25px;
  background-color: white;
  color:  black;
}

.DateBox input:focus {
  border: none !important;
}

.DateBox:not(:focus)::dx-show-clear-button {
  -webkit-appearance: none;
  display: none;
}

.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content {
  display: block;
}