.PageMessageLabel {
  line-height: 1.45;
  letter-spacing: normal;
  margin: 0px 0px 0px 5px;
  align-self: center;
  white-space: pre-wrap;
}

.PageMessageIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  float: left;
}

.PageMessageLabel,
.PageMessageIcon {
  color: #505050;
}

.PageMessage {
  background-color: #ffffde;
  border-radius: 2px;
  padding: 4px;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #505050;
}
