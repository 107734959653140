.search-criteria-div {
  overflow: hidden;
  width: 100% !important;
}

.search-criteria-div table {
  width: 100%;
}

/*
.search-criteria-div table td{
    width: 100%;
}
*/

.search-criteria-items {
  display: inline-block;
  margin-right: 14px;
  font-size: var(--main-font-size);
}

.refine-search {
  float: right;
  min-height: 35px;
}

.refine-search-icon {
  color: white;
}

.refine-search-button {
  width: 150px;
  color: white;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 5px 2px;
}

.step-content {
  width: 600px;
  /* padding-bottom: 100px; */
}

.main-info {
  border: none;
  padding: 0px;
}

.main-info td {
  padding: 2px;
  vertical-align: middle;
}

.main-info .operation-td {
  padding-top: 6px;
  vertical-align: top;
}

.main-info .operation-radio-td {
  padding-top: 8px;
  padding-left: 24px;
}

.radio-label {
  padding-left: 5px;
  vertical-align: middle;
}

.disable:disabled {
  opacity: 0.5;
}

.search-result {
  background-color: #eeeeee;
  border-top: 5px solid #0d83c9;
  padding-top: 0.5px;
}

.search-div {
  max-height: 285px;
  overflow: auto;
}

.search-table {
  background-color: white;
  font-size: var(--main-font-size);
  color: black;
}

.search-table th {
  background-color: #eeeeee;
  border: 0.5px solid #dddddd;
  color: black;
  flex: 100 0 auto;
  font-weight: normal;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 8px;
}

.search-table td {
  padding-left: 6px;
  padding-right: 6px;
  border: 0.5px solid #dddddd;
}

.search-table tr {
  padding-left: 6px;
  padding-right: 6px;
  border-collapse: collapse;
}

.search-table tr:hover {
  background-color: #eeeeee;
}

.search-footer {
  background-color: #eeeeee;
  font-size: var(--main-font-size);
  color: black;
}

.search-footer th {
  border: 0.5px solid #dddddd;
  font-weight: normal;
  padding-left: 6px;
  padding-right: 6px;
}

.search-criteria {
  border: 0px solid #474747;
  margin-bottom: 10px;
}

/*
.kdIzSA {
    font-size: 12px;
    white-space: nowrap;
}
*/

.lkFKhp {
  height: 35px;
}

.search-criteria tbody {
  padding: 4px !important;
  margin: 4px !important;
}

.search-criteria tr {
  border-collapse: collapse;
}

.search-criteria td {
  padding-bottom: 0px;
  /* padding-left: 5px;
    padding-right: 5px; */
  /* padding-top: 4px;     */
  vertical-align: middle;
}

.search-criteria-button {
  padding: 8px !important;
  text-align: right;
}

.Router > div {
  width: 100%;
}

.anchor-style {
  color: #007bff !important;
}

.anchor-style:hover {
  text-decoration: underline !important;
}

/* On Focus Style */

.virtus-select,
.virtus-textbox,
.virtus-text-area {
  position: relative;
}

.virtus-text-area::after,
.virtus-textbox::after,
.virtus-select::after {
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  content: '';
  position: absolute;
  height: 2px;
  background: #22a0f4;
  -webkit-transform: scale(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.02, 1);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.02, 1);
}

.virtus-text-area:focus-within::after,
.virtus-select:focus-within::after,
.virtus-textbox:focus-within::after {
  transform: scaleX(1);
}

.MuiInputBase-multiline-90 {
  padding: 0px !important;
}

/* ----------- */

/*#genesisAppContent {
    overflow: auto !important;
}*/

.Body__Wrapper-sc-1p9t1ml-0 {
  overflow: auto !important;
  width: 100% !important; 
  height: unset !important;
  padding: 0px !important;
}

#addModalLabel {
  vertical-align: top !important;
}

.dx-overlay-wrapper.dx-popup-wrapper.dx-overlay-modal.dx-overlay-shader {
  z-index: 1501 !important;
}

.dx-overlay-wrapper.dx-popup-wrapper.dx-popover-wrapper.dx-dropdownmenu-popup-wrapper.dx-dropdownmenu-popup.dx-popover-without-title.dx-position-bottom {
  z-index: 1501 !important;
}

.DxPopup {
  border: transparent !important;
  border-radius: 0% !important;
}

.DxPopup .dx-popup-title {
  padding: 0px !important;
  border: 0px !important;
}

.DxPopup .dx-popup-content {
  padding: 0 !important;
  background-color: rgb(28, 37, 46);
}
