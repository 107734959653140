.modal-header {
    border-radius: 0px;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 0px;
}

.modal-header table {
    width: 100%;
}

.modal-header .icons {
    width: auto;
    text-align: right;
}

.modal-header td {
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}

.modal-header .icons {
    float: right;
}

.modal-header img {
    margin-left: 5px;
    height: 24px;
    width: 24px;
    cursor: pointer;
}