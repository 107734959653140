.Main {
  margin: 4px;
}

.Selection {
  font-size: 13px;
  text-align: left;
  padding: 4px;
}

#dealListReinvestmentDiv select {
  width: 130px !important;
}
