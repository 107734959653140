body {
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 12px !important;
  overflow: auto !important;
  --main-font-size: 12px;
  --main-font-family: 'Nunito Sans', sans-serif;
  --highlight: yellow;
  --grey-separator: #515159;
  --accordion-tab-background: rgb(3, 16, 50);
  --modal-header-bg: #28333c;
  --negative-val-bg: #f20000;
  --positive-val-bg: #01b494;
  --text: var(--white);
  --dataGrid-header: #e6e6e6;
  --wizard-steps-background: #0d1924;
  --bg: #293a49;
}
