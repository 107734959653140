.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.hyperlink {
  color: var(--text);
  display: inline-block;
  cursor: pointer;
}

.hyperlink h6 {
  font-size: var(--main-font-size);
}

.no-hyperlink {
  color: var(--text);
  display: inline-block;
}

.no-hyperlink h6 {
  font-size: var(--main-font-size);
}

.hyperlink :hover {
  color: var(--light-blue);
  text-decoration: underline;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Router {
  flex: 1;
  display: flex;
  flex-grow: 1;
}

input[type='text']:disabled {
  background: #dddddd !important;
}

#genesisAppBody {
  overflow: hidden !important;
}
