.textbox::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.textbox:focus {
  padding-right: 0px !important;
}

.textbox:focus::-webkit-search-cancel-button {
  width: 12px;
  height: 12px;
  border-radius: 0px;
  content: url('../../images/clear_white.png');
}

.light:focus::-webkit-search-cancel-button {
  content: url('../../images/clear_black.png') !important;
}

.currencyTextBox::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.currencyTextBox:focus::-webkit-search-cancel-button {
  width: 12px;
  height: 12px;
  border-radius: 0px;
  content: url('../../images/clear_white.png');
}

.currencyTextBox.light:focus::-webkit-search-cancel-button {
  content: url('../../images/clear_black.png');
}
