.modal-content {
  max-width: 95vw;
  border: none;
  position: initial;
  font-size: var(--main-font-size);
}

.modal-content td {
  vertical-align: middle;
}

.DxPopup .dx-scrollable-container {
  max-height: 90vh;
}
