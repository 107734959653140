.success {
  background-color: #01b494;
}

.success #error {
  display: none;
}

.error {
  background-color: #f20000;
}

.error #check {
  display: none;
}

.notification {
  height: 70px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  border-radius: 2px;
  margin: 4px;
}

.notification > div:first-child {
  display: inline;
  float: left;
  color: white;
  height: 100%;
  width: 28px;
  position: relative;
}

.notification > div:first-child > div {
  position: absolute;
  margin: 0 2px 0 2px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.notification > div:first-child > div > img {
  height: 24px;
  width: 24px;
}

.notification > div:last-child {
  padding-left: 28px;
}

.toast-content {
  padding: 5px 10px;
}

.toast-content > p:first-child {
  font-weight: bold;
  font-size: 14px;
  color: #232323;
  margin: 0;
  display: inline;
}

.toast-content > p:nth-child(3) {
  display: block;
  margin: 0;
}

.toast-content > div {
  display: inline;
  float: right;
}

.toast-content > a {
  display: block;
}
