.inspector-search {
  background: #1c252e;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  overflow: auto;
}

.inspector-search .modal-content {
  align-items: center;
  max-height: calc(100vh - 115px);
}

.inspector-search .modal-footer {
  position: absolute;
  bottom: 38px;
}

.inspector-search .control-row > td:first-child {
  white-space: normal !important;
  line-height: unset !important;
}

.inspector-search select,
.inspector-search .textbox,
.inspector-search .ps-Search,
.inspector-search .select-main {
  width: 300px !important;
}

.inspector-search .currency-textbox {
  width: auto !important;
  /* padding-left: 20px !important; */
  margin-top: 2px;
  margin-bottom: 2px;
}

.characterLimit .textbox {
  width: 50px !important;
  min-width: 50px !important;
}
.characterLimit table {
  float: left;
}
