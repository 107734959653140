select {
  -webkit-appearance: none;
  padding-left: 6px;
  padding-right: 20px;
  min-width: 100px;
}
.select-light {
  background: url('../../../src/images/open-black.svg') no-repeat right;
  width: auto;
  background-color: #eeeeee;
  border: 0.5px solid #ccc;
}

.select-dark {
  background: url('../../../src/images/open-white.svg') no-repeat right;
  width: 25vw;
  background-color: #28333c;
  border-color: transparent;
}
