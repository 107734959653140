@font-face {
  font-family: DXIcons;
  src: url('./../Themes/icons/dxiconsmaterial.woff2') format('woff2'),
    url('./../Themes/icons/dxiconsmaterial.woff') format('woff'),
    url('./../Themes/icons/dxiconsmaterial.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.dx-fieldset {
  margin: 30px 20px;
  padding: 0;
}

.dx-fieldset,
.dx-fieldset * {
  box-sizing: border-box;
}

.dx-fieldset-header {
  margin: 0 0 20px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.dx-field:last-of-type {
  margin: 0;
}

.dx-field {
  position: relative;
  color: #012834;
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto, RobotoFallback, 'Noto Kufi Arabic', Helvetica, Arial, sans-serif;
  line-height: 1.2857;
  padding: 0;
  display: flex;
}

.dx-field::after,
.dx-field::before {
  display: table;
  content: '';
  line-height: 0;
}

.dx-field-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  cursor: default;
  align-self: center;
  padding-right: 15px;
  float: left;
  width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dx-field-value-static,
.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
  width: 60%;
}

.dx-texteditor.dx-editor-filled {
  background-color: #fff;
}

.dx-field-value.dx-widget,
.dx-field-value:not(.dx-widget) > .dx-widget {
  margin: 0;
}

.dx-fieldset .dx-field-value {
  margin: 0;
}

.dx-texteditor {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: relative;
  display: block;
}

.dx-show-clear-button {
  position: relative;
}

.dx-field-value,
.dx-field-value-static {
  float: right;
}

.dx-widget {
  display: block;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  padding: 0;
  outline: 0;
  -webkit-print-color-adjust: exact;
  color: #012834;
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto, RobotoFallback, 'Noto Kufi Arabic', Helvetica, Arial, sans-serif;
  line-height: 1.2857;
  box-sizing: border-box;
}

.dx-texteditor.dx-editor-filled.dx-state-hover {
  background-color: #fff;
}

.dx-field::after {
  clear: both;
}

.dx-texteditor::before {
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  content: '';
  position: absolute;
  z-index: 2;
  transform: scale(0);
}

.dx-texteditor-container {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.dx-texteditor.dx-editor-outlined::after {
  border-bottom: 1px solid #a7a7a7;
}

.dx-texteditor::after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  content: '';
  position: absolute;
}

.dx-texteditor-input-container {
  display: flex;
  flex-grow: 1;
  position: relative;
  align-items: baseline;
}

.dx-texteditor.dx-editor-filled .dx-lookup-field,
.dx-texteditor.dx-editor-filled .dx-placeholder::before,
.dx-texteditor.dx-editor-filled .dx-texteditor-input,
.dx-texteditor.dx-editor-outlined .dx-lookup-field,
.dx-texteditor.dx-editor-outlined .dx-placeholder::before,
.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  padding: 15px 16px 14px;
}

.dx-field input,
.dx-field textarea {
  font-family: Roboto, RobotoFallback, 'Noto Kufi Arabic', Helvetica, Arial, sans-serif;
  line-height: 1.2857;
}

.dx-texteditor-input {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
  margin: 0;
  background-color: transparent;
  color: #012834;
  font-size: 16px;
}

.dx-placeholder {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  max-width: 100%;
  width: auto;
  height: 100%;
  text-align: left;
  cursor: text;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  line-height: normal;
}

.dx-hidden,
.dx-state-invisible {
  display: none !important;
}

.dx-texteditor-buttons-container {
  display: flex;
  width: auto;
  flex-grow: 0;
  flex-basis: content;
  align-items: center;
}

.dx-texteditor .dx-texteditor-buttons-container:last-child > .dx-button:last-child,
.dx-texteditor .dx-texteditor-buttons-container:last-child > .dx-clear-button-area:last-child {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
}

.dx-texteditor .dx-texteditor-buttons-container:last-child > .dx-button:first-child,
.dx-texteditor .dx-texteditor-buttons-container:last-child > .dx-clear-button-area:first-child {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}

.dx-texteditor .dx-texteditor-buttons-container:last-child > .dx-clear-button-area,
.dx-texteditor .dx-texteditor-buttons-container:last-child > .dx-dropdowneditor-button:last-child,
.dx-texteditor .dx-texteditor-buttons-container:last-child > .dx-numberbox-spin-container:last-child {
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
}

.dx-texteditor .dx-texteditor-buttons-container > .dx-button,
.dx-texteditor .dx-texteditor-buttons-container > .dx-clear-button-area {
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
  -webkit-margin-end: 5px;
  margin-inline-end: 5px;
}

.dx-show-clear-button .dx-clear-button-area {
  width: 22px;
  min-width: 22px;
  right: 0;
}

.dx-texteditor-empty .dx-clear-button-area {
  display: none;
}

.dx-clear-button-area {
  height: 100%;
  width: 34px;
  position: relative;
  cursor: pointer;
  text-align: justify;
}

.dx-show-clear-button .dx-icon-clear {
  color: #25c8fb;
  background-color: #bbeefe;
  border-radius: 50%;
  position: absolute;
  top: 60%;
  margin-top: -11px;
  width: 15px;
  height: 15px;
  background-position: 0 0;
  background-size: 22px 22px;
  padding: 0;
  font-size: 8px;
  text-align: center;
  line-height: 12px;
}

.dx-clear-button-area .dx-icon-clear {
  position: absolute;
  display: inline-block;
  background-size: contain;
}

.dx-icon {
  background-position: 50% 50%;
}

.dx-icon-clear,
.dx-icon-clearcircle,
.dx-icon-clearformat {
  background-position: 0 0;
  background-repeat: no-repeat;
}

.dx-icon {
  font-size: 24px;
  line-height: 1;
  white-space: nowrap;
  font-feature-settings: 'liga';
}

.dx-icon {
  display: inline-block;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dx-field-value.dx-widget.dx-texteditor.dx-state-active::before,
.dx-field-value.dx-widget.dx-texteditor.dx-state-focused::before {
  border-bottom: 2px solid #0d83c9;
  transform: scale(1);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.02, 1);
}

.dx-texteditor.dx-state-active::before,
.dx-texteditor.dx-state-focused::before {
  border-bottom: 2px solid #ffc845;
  transform: scale(1);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.02, 1);
}

.dx-show-clear-button .dx-icon-clear::before {
  position: absolute;
  display: block;
  width: 12px;
  top: 50%;
  margin-top: -6px;
  left: 50%;
  margin-left: -6px;
}

.dx-icon-clear,
.dx-icon-clearcircle,
.dx-icon-clearformat,
.dx-icon-clearsquare {
  font: 14px/1 DXIcons;
}
.dx-icon-clear:before {
  content: '\f008';
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
